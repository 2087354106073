import router from "../router";

class Auth {
  setToken(token, refresh) {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refresh);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getRefreshToken() {
    return localStorage.getItem("refresh");
  }

  logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    // setTimeout(() => {
      // router.push({ name: "login" });
      router.push({ path: "/" });
    // }, 100);
  }

  loggedIn() {
    return !!this.getToken();
  }

  getHeaders(multy = false) {
    const token = this.getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-user-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };
    if (multy) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  }
}

export const auth = new Auth();
