<template>

  <b-modal
      centered
      :ok-disabled="true"
      id="edit-order"
      :no-close-on-backdrop="true"
      :static="true"
      @show="loadOrderInfo"
      @close="cancelOrderX"

  >
    <template v-slot:modal-title><code><h1>Set QTY</h1> {{order.make}} {{order.part_number}}</code></template>
    <template v-slot:modal-footer>
      <b-button variant="warning" size="sm" @click="cancelOrder">Отмена</b-button>
      <b-button variant="success" size="sm" @click="saveOrder" v-if="showSave">Сохранить</b-button>
    </template>
    <div class="d-block">
      <table class="table table-sm table-borderless table-responsive-sm">
        <tbody>
        <tr v-if="order.make === undefined">
          <td>Make:</td>
          <td>
            <autocomplete-input v-model="make" />
          </td>
        </tr>
        <tr v-if="order.part_number === undefined">
          <td>PartNumber:</td>
          <td>
            <b-input-group class="mt-0">
              <b-form-input type="text" class="form-control" v-model="part_number"></b-form-input>
            </b-input-group>
          </td>
        </tr>
        <tr><td>QTY:</td>
          <td>
            <b-input-group class="mt-0">
              <b-form-input type="number" class="form-control" ref="input_qty" v-model="recv"></b-form-input>
                <NumbersModal :numberString.sync="recv" :input_name="`inputQty`" :mkey="`recv`"></NumbersModal>
            </b-input-group>
          </td>
        </tr>
        <tr v-if="!order.has_barcode">
          <td>Barcode: </td>
          <td>
            <b-input-group class="mt-0">
              <b-form-input type="text" class="form-control"  ref="input_barcode" v-model="barcode_full"></b-form-input>
            </b-input-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import mixins from "@/services/mixins";
import Http from "@/services/Http";
import eventBus from "@/services/eventBus";
import NumbersModal from "@/components/NumbersModal.vue";
import AutocompleteInput from "@/components/support/AutocompleteInput.vue";

export default {
  components: {
    NumbersModal,
    AutocompleteInput,
  },
  strict: true,
  data: () => ({
    order: {},
    recv: null,
    make: null,
    part_number: null,
    barcode: null,
    barcode_full: null,
    touch_timer: null,
    timerId: null
  }),
  name: 'EditOrderModals',
  mixins: [mixins],
  computed: {
    showSave() {
      // if (this.cancelIncome || this.toWaiting || parseInt(this.order.self_order) === 1) {
        return true;
      // }
      // return false;

    },
  },
  watch: {
    part_number: function (newNumber) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        if (this.make.toLowerCase() === 'ford') {
          // Удаляем символ 'P' в начале строки и любые символы кроме латинских букв и цифр
          this.part_number = newNumber.replace(/^P|[^a-zA-Z0-9]/g, '');
        }
        if (this.make.toLowerCase() === 'motorcraft') {
          // Удаляем символ 'P' в начале строки и любые символы кроме латинских букв и цифр
          this.part_number = newNumber.replace(/^P|[^a-zA-Z0-9]/g, '');
        }
      }, 250);

    }
  },
  methods: {
    ...mapMutations(["setOrderParam"]),
    setNextFocus: function () {
      setTimeout(() => {
        let setFocus = this.$refs.input_barcode;

        if(this.recv === null || this.recv === ''){
          setFocus = this.$refs.input_qty
        }

        setFocus.focus()
      }, 200);

    },


    cancelOrder: function () {
      this.$store.commit('setOrder', {'order': {}});
      eventBus.$emit('clean-text-input')
      this.$bvModal.hide('edit-order')
    },
    cancelOrderX: function () {
      // this.$store.commit('setOrder', {'order': {}});
      // eventBus.$emit('clean-text-input')
      this.$bvModal.hide('edit-order')
    },
    focusInputQty: function () {
        setTimeout(() => {
          this.$refs.input_qty.focus()
        }, 100)
    },
    loadOrderInfo: function () {
      this.make =  null
      this.part_number =  null
      this.barcode_full = null
      this.recv = null
      this.order = {
        'part_number': undefined,
        'barcode': undefined,
        'entry': undefined,
        'make': undefined,
        'is_client': undefined,
      }
      if(this.$store.getters.getOrder !== undefined) {
        this.order = JSON.parse(JSON.stringify(this.$store.getters.getOrder))
        this.make = this.order.make
        this.part_number = this.order.part_number
        console.log(this.order.is_client)

        this.focusInputQty()
        if(this.order.entry !== undefined && this.order.entry !== null) {
          setTimeout(() => {
            Http.touchOrder(this.order.entry);
          }, 1000)
        }
      }

    },
    userCode: function(user_id) {
      let user = this.$store.getters.getUsers.find(user => user.id === user_id);
      if (user) {
        return user.code;
      }
      return "No Code";
    },
    saveOrder: function () {
      if(this.order.is_client === undefined || this.order.is_client === null){
        this.order.is_client = 0
      }

      if(!this.order.is_client) {
        this.doSaveParts()
      }else{
        this.doPlaceParts()
      }
      // eventBus.$emit('clean-text-input')
    },
    doPlaceParts: function() {
      if(parseInt(this.recv) < 1){
        this.$bvToast.toast('Нужно указать количство', {
          title: 'Ошибка',
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      }

      Http.doPartsPlacePartial(
        {
          "recv": this.recv,
          "entry":parseInt(this.order.entry),
          "barcode_full": this.barcode_full,
          "make": this.make,
          "part_number": this.part_number,
        })
        .then(() => {
          let audio = new Audio(require('@/assets/sound/material_ok.wav'));
          audio.play();
          this.$bvModal.hide('edit-order')
          eventBus.$emit('clean-text-input')
        })
        .catch(() => {
          //toast error save
          let audio = new Audio(require('@/assets/sound/material_alert.wav'));
          audio.play();
          this.$bvToast.toast('Ошибка сохранения', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        })
    },
    doSaveParts: function (){

      if(parseInt(this.recv) < 1){
        this.$bvToast.toast('Нужно указать количство', {
          title: 'Ошибка',
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      }

      Http.doPartsReceivedPartial(
        {
          "recv": this.recv,
          "entry":parseInt(this.order.entry),
          "barcode_full": this.barcode_full,
          "make": this.make,
          "part_number": this.part_number,
        })
        .then(() => {
          let audio = new Audio(require('@/assets/sound/material_ok.wav'));
          audio.play();
          this.$bvModal.hide('edit-order')
          eventBus.$emit('clean-text-input')
        })
        .catch(() => {
          //toast error save
          let audio = new Audio(require('@/assets/sound/material_alert.wav'));
          audio.play();
          this.$bvToast.toast('Ошибка сохранения', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        })
    }
  },
  mounted() {
    this.order = {}
    this.make = null
    this.part_number = null
    eventBus.$on('clean-inputWeight', () => {
      console.log('on clean-inputWeight')
      this.setNextFocus()
    });
    eventBus.$on('clean-inputQty', () => {
      console.log('on clean-inputQty')
      this.setNextFocus()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off('clean-inputWeight')
    eventBus.$off('clean-inputQty')
    this.order = {}
    this.make = null
    this.part_number = null
  }
}
</script>

<style scoped>
.table-sm th, .table-sm td {
  padding: 0.15rem;
}
.text_green {
  color: green;
}
.text_red {
  color: red;
}
</style>
