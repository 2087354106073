import { mapMutations } from 'vuex';
import Http from "@/services/Http";
export default {
  data: () =>({
    reload: false,
    modalAutoOpen: true,
  }),
  methods: {
    ...mapMutations(["setCheckedInvoice", "setOrder", "setBarcodes", "setOrders", "setMakes"]),
    roadColor(shipping_type) {
      return {
        text_green: shipping_type === 'euro',
        text_red: shipping_type === 'express'
      }
    },
    getUserCode: function (user_id)  {

      if (user_id === 1) {return 'G'}
      if (user_id === 2) {return 'A'}
      if (user_id === 3) {return 'D'}
      if (user_id === 4) {return 'VV'}
      if (user_id === 5) {return 'VL'}

    },
    editOrder: function (order, is_client) {
      // Редактирование заказа
      if(typeof order !== "undefined"){
        order.is_client = is_client;
      }

      this.setOrder({order: order})
      this.$bvModal.show(`edit-order`)
    },
    loadAllList(search_pn) {
      // Загрузка списка ордеров
      if(search_pn === undefined || search_pn === null || search_pn === "" || search_pn.length < 3) {
        this.$store.commit('setOrder', {'order': null});
        this.$store.commit('setBarcodes', {'barcodes': []});
        this.$store.commit('setOrders', {'orders': []});
        return
      }

      Http.getAll(search_pn)
      .then((response) => {
        this.setOrders({orders: response.data.orders});
        this.setBarcodes({barcodes: response.data.barcodes});
      })
      .catch((error) => {
        console.log(error)
      })
    },
    loadAllReceived(search_pn) {
      // Загрузка списка ордеров
      if(search_pn === undefined || search_pn === null || search_pn === "" || search_pn.length < 3) {
        this.$store.commit('setOrder', {'order': null});
        this.$store.commit('setBarcodes', {'barcodes': []});
        this.$store.commit('setOrders', {'orders': []});
        return
      }

      Http.getAllReceived(search_pn)
          .then((response) => {
            this.setOrders({orders: response.data});
          })
          .catch((error) => {
            console.log(error)
          })
    },
    loadHistory: function (limit) {
      Http.recvHistory(limit)
          .then((response) => {
            this.history = response.data
          })
          .catch((error) => {
            console.log(error)
          })
    },
    loadMakes: function () {
      Http.doGetMakes()
          .then((response) => {
            this.setMakes(response.data.makes)
          })
          .catch((error) => {
            console.log(error)
          })
    },
    loadPlaceHistory: function (limit) {
      Http.placeHistory(limit)
          .then((response) => {
            this.history = response.data
          })
          .catch((error) => {
            console.log(error)
          })
    }

  },
  computed: {
    countUsers: function (){
      return this.$store.getters.getUsers.length
    },
  }
}

