export default {
    USER: {},
    user: null,
    auth: {
        loggedIn: false,
    },
    users: [],
    orders: [],
    barcodes: [],
    order: null,
    makes: [],
}
