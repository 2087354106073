import Http from '@/services/Http'

let _jwtTimer = null;
const expireTime = 1000 * 60 * 59;

export default {
    // Запускаем таймер, который обновляет токен каждые 59 минут
    REFRESH_TOKEN({ commit, dispatch }) {
        return Http.refreshToken(localStorage.getItem('token'))
            .then(resp => {
                commit('SET_USER', resp.data);

                if (_jwtTimer) {
                    clearInterval(_jwtTimer);
                    _jwtTimer = null;
                }

                _jwtTimer = setInterval(() => dispatch('REFRESH_TOKEN'), expireTime);
            })
    }
}
