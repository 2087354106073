export default {
    ROLE_IS: state => role => {
        if(!state.USER.groups) return false;

        return state.USER.groups.some(group => group === role)
    },
    getCurrentUser: state => state.user,
    getUsers: state => state.users,
    getOrder: state => state.order,
    getOrders: state => state.orders,
    getBarcodes: state => state.barcodes,
    getMakes: state => state.makes
}
