import router from '@/router'
import Vue from "vue";

export default {
    // auth
    SET_USER (state, user) {
        state.USER = user;
        localStorage.setItem('token', user.token);
    },

    CLEAR_USER (state) {
        state.USER = {};
        localStorage.removeItem('token');
        router.push({ name: 'login'});
    },
    setOrder(state, payload) {
        state.order = payload.order;
    },
    setLoggedIn(state, isLoggedIn) {
        state.auth.loggedIn = isLoggedIn;
    },
    setCurrentUser(state, user) {
        state.user = user;
    },
    setCheckedType(state, checkedType) {
        state.checkedType = checkedType;
    },
    setUsers(state, payload) {
        state.users = payload.users;
    },
    setOrders(state, payload) {
        state.orders = payload.orders;
    },
    setBarcodes(state, payload) {
        state.barcodes = payload.barcodes;
    },
    addOrders(state, payload) {
        state.orders.push(payload.order);
    },
    setOrderParam(state, payload) {
        Vue.prototype.$socket.sendObj({
            "type": "param",
            "payload": payload
        });
    },
    setMakes(state, makes) {
        state.makes = makes
    }
}
