<template>
  <b-input-group-append>
  <b-button v-b-modal="`numbers-modal-${mkey}`" variant="outline-success"><b-icon icon="calculator"></b-icon></b-button>
  <b-modal :id="`numbers-modal-${mkey}`" :no-close-on-backdrop="true" @ok="applyNumber" @cancel="cancelInput" size="sm">
    <template v-slot:modal-title>{{currentNumber}}</template>
    <b-form>
<!--      create number keyboard  from 1 to 0 looks like pinpad -->
      <div class="row m-0">
        <div class="col-4 p-1"><b-button style="width: 100%" variant="outline-success" @click="addNumber('1')" size="lg">1</b-button></div>
        <div class="col-4 p-1"><b-button style="width: 100%" variant="outline-success" @click="addNumber('2')" size="lg">2</b-button></div>
        <div class="col-4 p-1"><b-button style="width: 100%" variant="outline-success" @click="addNumber('3')" size="lg">3</b-button></div>
      </div>
      <div class="row m-0">
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('4')">4</b-button></div>
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('5')">5</b-button></div>
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('6')">6</b-button></div>
      </div>
      <div class="row m-0">
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('7')">7</b-button></div>
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('8')">8</b-button></div>
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('9')">9</b-button></div>
      </div>
      <div class="row m-0">
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-warning" @click="addNumberAll(maxqty)">{{maxqty}}</b-button></div>
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-success" @click="addNumber('0')">0</b-button></div>
        <div class="col-4  p-1"><b-button style="width: 100%"  variant="outline-danger" @click="clearInput">C</b-button></div>
      </div>

    </b-form>
  </b-modal>
  </b-input-group-append>

</template>

<script>
import eventBus from "@/services/eventBus";
  export default {
    strict: true,
    props: {
      numberString: {
        type: String,
        required: false
      },
      maxqty: {
        type: String,
        required: false
      },
      mkey: {
        type: String,
        required: true
      },
      input_name: {
        type: String,
        required: true
      }
    },
    data: () => ({
      currentNumber: "",
    }),
    name: 'NumbersModal',
    methods: {
      addNumber(number) {
        if(this.currentNumber === null){
          this.currentNumber = number;
        } else {
          this.currentNumber += number;
        }
      },
      addNumberAll(number) {
        this.currentNumber = number;
        this.applyNumber();
        this.$bvModal.hide('numbers-modal-' + this.mkey);
      },
      clearInput() {
        this.currentNumber = "";
      },
      applyNumber() {
        this.$emit('update:numberString', this.currentNumber);
        let event_name = 'clean-' + this.input_name
        console.log(event_name)
        eventBus.$emit(event_name)
        this.currentNumber = "";
      },
      cancelInput() {
        this.$emit('update:numberString', this.numberString);
        let event_name = 'clean-' + this.input_name
        console.log(event_name)
        eventBus.$emit(event_name)
        this.currentNumber = "";
      }
    },
    mounted() {
      if(this.numberString !== null){
        this.currentNumber = this.numberString;
      }else{
        this.currentNumber = null;
      }
    }
  }
</script>

<style scoped>
.table-sm th, .table-sm td {
  padding: 0.15rem;
}
.text_green {
  color: green;
}
.text_red {
  color: red;
}
</style>
