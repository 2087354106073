import Vue from 'vue'
import App from './App.vue'
import router from "@/router"; // импорт роутера
import VueRouter from "vue-router"; // импорт библиотеки vue-router
import Vuex from 'vuex'; // импорт Vuex для управления состояниями
import VueAxios from 'vue-axios'; // импорт VueAxios для работы с axios
import axios from 'axios'; // импорт Axios для HTTP-запросов
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'; // Импорт BootstrapVue и IconsPlugin

// Импорт стилей Bootstrap и BootstrapVue
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from "@/store";

// Настройки Vue
Vue.config.productionTip = true;

// Использование различных плагинов
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
