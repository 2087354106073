<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" sticky fixed="top">
    <b-navbar-brand  :to="{name: 'receive'}"> {{pageTitle}}</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
<!--        <b-nav-item :to="{name: 'parts'}">Parts</b-nav-item>-->
<!--        <b-nav-item :to="{name: 'tracks'}">Tracking</b-nav-item>-->
        <b-nav-item :to="{name: 'receive'}">First Receive</b-nav-item>
        <b-nav-item :to="{name: 'history'}">RECV History</b-nav-item>
        <b-nav-item :to="{name: 'toclients'}">Second to Clients</b-nav-item>
        <b-nav-item :to="{name: 'placehistory'}">PLACE History</b-nav-item>
        <b-nav-item :to="{name: 'scanner'}">Scanner</b-nav-item>
<!--        <b-nav-item :to="{name: 'box_tracks'}">BOX & Tracks</b-nav-item>-->
<!--        <b-nav-item :to="{name: 'labels'}">Labels</b-nav-item>-->
<!--        <b-nav-item :to="{name: 'weight'}">Weight</b-nav-item>-->
<!--        <b-nav-item :to="{name: 'client_box'}">BOX</b-nav-item>-->
<!--        <b-nav-item :to="{name: 'summary'}">Summary</b-nav-item>-->
<!--        <b-nav-item :to="{name: 'settings'}">Settings</b-nav-item>-->

      </b-navbar-nav>
      <hr/>
      <b-navbar-nav class="ml-auto">
        <b-nav-item @click="userLogout()">
          <b-icon icon="box-arrow-right"></b-icon>
          Logout</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapMutations } from "vuex";
import Http from "@/services/Http";
import mixins from "@/services/mixins";
import { auth } from "@/services/auth";

export default {
  name: "SiteMenu",
  mixins: [mixins],
  components: {  },
  data() {
    return {
      mobileMenuVisible: false,
      fast_search: null,
      selected_client: "",
      selected_makes: "",
    };
  },
  watch: {
    $route(){
      this.mobileMenuVisible = false;
    }
  },
  methods: {
    ...mapMutations(["setUsers"]),
    userLogout: function () {
      if(confirm("Are you sure you want to logout?")){
        auth.logOut();
        this.$store.commit('setLoggedIn', false);
        this.$router.push({ name: "login" });
      }
    },
    loadUsers: function () {
      if (this.countUsers > 0) {
        return;
      }
      Http.getAllUser()
          .then((resp) => {
            this.setUsers({ users: resp.data });
          })
          .catch(() => {
            alert("Loading All users Error #75");
          });
    },
  },
  computed: {
    pageTitle: function () {
      if(this.$route.name === null){
        return "WWP WH";
      }
      return this.$route.name.toUpperCase();
    },
  },
  created() {
    // if this.$store.getters.getCurrentUser is null , do logout

    this.loadUsers();
  },
  mounted() {
    if (this.$store.getters.getCurrentUser === null) {
      auth.logOut();
      this.$store.commit('setLoggedIn', false);
      this.$router.push({ name: "login" });
    }
    this.loadUsers();
  },
};
</script>

<style scoped>
 .secondary {
   color: #6c757d !important;
 }
 .success {
   color: #28a745 !important;
 }
</style>
