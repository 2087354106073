import axios from 'axios'
import {auth} from "@/services/auth";
// import router from "@/router";
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
// import router from "@/router";
// import store from '@/store'

// axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     function(error) {
//       if (error.response.status === 403) {
//         alert("Ошибка авторизации , токен просрочен");
//         // router.push({ name: "login" });
//       }
//       return Promise.reject(error);
//     }
// );


const API_URL = `https://lk.wwpartsnyc.com/api`;
const PAL_API_URL = `https://new.partsliner.com/manager/api/v4`;

axios.interceptors.response.use((response) => {
  auth
  return response
}, async function (error) {
  const originalRequest = error.config;

  if(originalRequest.url===`${API_URL}/token/refresh_token/`)
    return

  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const resp = await axios.post(`${API_URL}/token/refresh_token/`, {
      "refresh": auth.getRefreshToken(),
    });
    if(resp === undefined) {
      auth.logOut();
      document.location.href="/";
    }
    if(resp.data.token && resp.data.refresh){
      auth.setToken(resp.data.token, resp.data.refresh);
      originalRequest.headers['Authorization'] = 'Bearer ' + resp.data.token;
      return axios(originalRequest);
    }
  } else {
    return Promise.reject(error);
  }
});



// axios.defaults.withCredentials = true

export default {

  getHeaders () {
    const token = auth.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}` ,
        // 'Content-Type': 'multipart/form-data'
      },
      // withCredentials: true
    }
  },
  getXLSHeaders () {
    const token = auth.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}` ,
      },
      responseType: 'blob',
      // withCredentials: true
    }
  },
  getHeadersFormData () {
    const token = auth.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  },

  get (url) {
    return axios.get(url, this.getHeaders())
  },

  getUserToken(username, password){
     return axios.post(`${API_URL}/token/get_token/`, {
       "username": username,
       "password": password,
     })
  },

  postXLS (url, data) {
    return axios.post(url, data, this.getXLSHeaders())
  },

  post (url, data) {
    return axios.post(url, data, this.getHeaders())
  },

  postFormData (url, data) {
    return axios.post(url, data, this.getHeadersFormData())
  },

  delete (url, data) {
    return axios.delete(url, { headers: this.getHeaders().headers, data  })
  },

  put (url, data) {
    return axios.put(url, data, this.getHeaders())
  },

  auth (user) {
    return axios.post(`${API_URL}/token/`, user)
  },

  refreshToken (token) {
    return axios.post(`${API_URL}/token/refresh/`, { token })
  },
  getAllUser() {
    return this.get(`${API_URL}/clients/loadall/` )
  },
  getAll(search_pn) {
    return this.get(`${API_URL}/orders/loadall/?q=${search_pn}` )
  },
  getAllReceived(search_pn) {
    return this.get(`${API_URL}/orders/loadreceived/?q=${search_pn}` )
  },
  touchOrder(entry) {
    // {'search': '1234567890'}
    return this.get(`${API_URL}/orders/touch_order/?entry=${entry}` )
  },
  recvHistory(limit) {
    return this.post(`${API_URL}/parts/history/`, {limit: limit} )
  },
  placeHistory(limit) {
    return this.post(`${API_URL}/parts/place_history/`, {limit: limit} )
  },
  doPartsReceived(data) {
    return this.post(`${API_URL}/parts/received/`, data )
  },
  doPartsReceivedPartial(data) {
    return this.post(`${API_URL}/parts/partial/`, data )
  },
  doPartsPlacePartial(data) {
    return this.post(`${API_URL}/parts/place_partial/`, data )
  },
  doScanLog(data) {
    return this.post(`${API_URL}/parts/scannlog/`, data )
  },
  doGetMakes() {
    return this.get(`${API_URL}/parts/getMakes/`)
  },
  getPalScannerTracklist() {
    return this.get(`${PAL_API_URL}/scanner/tracklist/`)
  },
  doPalScannerScan(barcode) {
    return this.post(`${PAL_API_URL}/scanner/scan/`, {barcode: barcode})
  },
  doPalScannerDeleteTrack(id) {
    return this.post(`${PAL_API_URL}/scanner/deletetrack/`, {id: id} )
  },

}


