<template>
  <div id="app" class="p-0">
  <SiteMenu v-if="$store.state.auth.loggedIn"/>
    <router-view/>
    <EditOrderModals/>
  </div>
</template>

<script>

import SiteMenu from "@/components/SiteMenu.vue";
import {auth} from "@/services/auth";
import EditOrderModals from "@/components/EditOrderModals.vue";

export default {
  name: 'App',
  components: {
    EditOrderModals,
    SiteMenu,
  },
  created() {
    if(auth.loggedIn()) this.$store.commit('setLoggedIn', true);
  }
}
</script>

<style>

</style>
